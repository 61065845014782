#landingScreen,
.card,
.details-container,
.questions-page {
    justify-content: center;
    display: flex;
}
.CarouselBackwards::before,
.CarouselForward::before {
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    font-size: 1.5rem;
}
#landingScreen,
.lockScroll {
    overflow: hidden;
}
.landingImage,
.landingVideo {
    object-fit: fill;
    min-width: 100vw;
    overflow: clip;
}
#landingScreen,
.card,
.overview-button:hover {
    background-color: #fff;
}
#overview,
#problem,
.about,
.aboutTitle {
    background-attachment: fixed;
}
html {
    font-size: 100%;
    scroll-behavior: smooth;
    font-family: Barlow, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.titleBottom,
.titleTop {
    border-bottom: 1.525rem solid #555;
    border-left: 2.8125rem solid transparent;
    border-right: 2.8125rem solid transparent;
    height: 0;
    width: 25rem;
}
.card,
.verticalLinks a:hover::before {
    width: 100%;
}
.card {
    border-radius: 0.625rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.3125rem 0.9375rem;
    height: 100%;
    font-size: 2.5rem;
    align-items: center;
}
.main-wrapper,
.verticalLinks {
    display: flex;
    flex-direction: column;
}
body * {
    font-family: Barlow, serif;
}
#landingScreen {
    height: 100vh;
}
.title,
.verticalLinks {
    justify-content: center;
}
.landingImage {
    display: none;
}
#hamburgerNav {
    width: 3.75rem;
    height: 2.8125rem;
    position: absolute;
    z-index: 3;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    outline: 0;
}
#hamburgerNav span {
    display: block;
    position: absolute;
    height: 0.5625rem;
    width: 100%;
    background: #000;
    border-radius: 0.5625rem;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: 0.5s ease-in-out;
}
#wrapper > div,
.verticalLinks a {
    display: inline-block;
}
#hamburgerNav:hover span {
    background-color: #a9a9a9;
}
#hamburgerNav span:first-child {
    top: 0;
    transform-origin: left center;
}
#hamburgerNav span:nth-child(2) {
    top: 1.3125rem;
    transform-origin: left center;
}
#hamburgerNav span:nth-child(3) {
    top: 2.625rem;
    transform-origin: left center;
}
#hamburgerNav.active span:first-child {
    transform: rotate(45deg);
}
#hamburgerNav.active span:nth-child(2) {
    width: 0%;
    opacity: 0;
    transition: 0.1s;
}
#hamburgerNav.active span:nth-child(3) {
    transform: rotate(-45deg);
}
.opacityLayer {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
}
#verticalNav {
    backdrop-filter: blur(3.3125rem);
    -webkit-backdrop-filter: blur(3.3125rem);
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    opacity: 0;
    visibility: hidden;
}
#verticalNav.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 2s, visibility;
}
.verticalLinks {
    background: rgba(255, 255, 255, 0.8);
    border: 0.0625rem solid rgba(255, 255, 255, 0.18);
    width: 90vw;
    padding: 7.5rem 0;
    align-items: center;
}
.TOSS,
.horizontalNav {
    width: 100vw;
    z-index: 1;
}
.overviewInfoContainer,
.questions-list,
.title {
    flex-direction: column;
    display: flex;
}
.verticalLinks a {
    text-decoration: none;
    padding: 1.5rem 0;
    font-size: 2rem;
    text-align: center;
    background-image: linear-gradient(to right, #a9a9a9, #a9a9a9 50%, #000 50%);
    background-size: 200% 100%;
    background-position: -100%;
    position: relative;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.3s ease-in-out;
}
.verticalLinks a:before {
    content: "";
    background: #a9a9a9;
    display: block;
    position: absolute;
    bottom: -0.1875rem;
    left: 0;
    width: 0;
    height: 0.1875rem;
    transition: 0.3s ease-in-out;
}
.verticalLinks a:hover {
    background-position: 0;
}
.title {
    position: relative;
    align-items: center;
    opacity: 1;
    overflow: hidden;
    color: #fff;
}
#overview,
#problem,
.TOSS {
    overflow: clip;
}
.title h2 {
    font-size: 3rem;
    margin: 0;
}
.subTitle {
    font-size: 4rem;
    margin: 0;
    color: #fff;
}
.TOSS {
    position: absolute;
    height: 100vh;
    background-image: url("./images/TOSSLogo2.webp");
    background-size: 65%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.25);
}
.fadeIn {
    animation: 7s linear fadeAnimation;
}
@keyframes fadeAnimation {
    0%,
    35%,
    40% {
        opacity: 0;
    }
    100%,
    40% {
        opacity: 1;
    }
}
.introVideoContainer,
.product {
    display: flex;
    justify-content: center;
    align-items: center;
}
.product img {
    width: 30vw;
}
.horizontalNav {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(0.625rem);
    -webkit-backdrop-filter: blur(5.3125rem);
    height: 6.35rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
}
#overview,
#problem,
.imageContainer {
    background-size: cover;
}
.imageContainer {
    position: relative;
    top: 0.4375rem;
    margin-left: 3rem;
    height: 100%;
    width: 15rem;
    background-image: url("./images/TOSSLogo2.webp");
    background-position: center;
}
.horizontalLinks {
    width: 50%;
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#introVideo,
#overview {
    justify-content: center;
    display: flex;
    height: 100vh;
}
.horizontalLinks a {
    font-size: 1.75rem;
    text-decoration: none;
    color: #000;
    margin-right: 3rem;
}
.horizontalLinks a:hover {
    text-decoration: underline grey;
}
#homeAnimation,
.scrollCheck {
    width: 100vw;
    height: 100vh;
}
#introVideo {
    position: relative;
    align-items: center;
}
.playVideoButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.playVideoButton:hover {
    background-color: rgba(255, 255, 255, 0.6);
}
.playVideoButton:active {
    transform: translate(-50%, -50%) scale(0.95);
}
#overview {
    align-content: center;
    background-color: #bfbfbf;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23aaaaaa' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23c6c6c6' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%237b7b7b' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23636363' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23787878' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%236a6a6a' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23666666' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23858585' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%239e9e9e' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%239b9b9b' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23bebebe' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%237e7e7e' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
}
#overview .subTitle {
    padding-bottom: 1rem;
}
.overviewInfoContainer p {
    border-top: 0.1875rem solid #a9a9a9;
    font-size: 2rem;
    margin: 0 5rem;
    padding: 3rem 0;
    text-align: center;
    color: #fff;
}
.overviewInfoContainer {
    justify-content: center;
    align-items: center;
    width: 50vw;
}
.overviewImageContainer {
    width: 50vw;
    background-image: url("./images/slide5.webp");
    background-position: center;
    background-size: cover;
}
.overview-button {
    font-size: 1.5rem;
    background-color: #d3d3d3;
    color: #000;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 1.25rem;
    transition: background-color 0.3s;
    cursor: pointer;
}
#problem .subTitle {
    font-size: 5rem;
    margin-right: 9rem;
    text-shadow: 0.25rem 0.25rem 0.5rem #000;
    position: relative;
    top: 5.3125rem;
    left: 4.0625rem;
}
#problem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    background-color: #e4e4e4;
    background-image: url("./images/referee.webp");
}
.carousel {
    white-space: nowrap;
    transition: transform 0.3s;
}
.carouselItem {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 33vh;
    color: #000;
}
#coachProduct,
#refProduct,
.coachInfo,
.contact-container,
.contact-title,
.details,
.journeySlide,
.journeySubject,
.modal,
.modalContainerCoach,
.modalContainerRef,
.question,
.question-container,
.refInfo,
.subjects,
.wrapper {
    display: flex;
    display: flex;
}
.carouselItemTitle {
    position: relative;
    top: 1rem;
    left: 10rem;
}
.verticalVideo {
    background: #000;
}
.about,
.aboutTitle,
.contact-image-container,
.image-1 .detail-image-container,
.journeySlide {
    background-size: cover;
    background-position: center center;
}
.carouselButtons > button {
    margin: 0.3125rem;
}
.CarouselBackwards,
.CarouselForward {
    margin: 0.625rem;
    background-color: #fff;
    border: transparent;
    padding: 0.4375rem;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 31.25rem;
}
.CarouselBackwards::before {
    content: "\f04a";
}
.CarouselForward::before {
    content: "\f04e";
}
.main-wrapper {
    align-items: center;
    width: 100%;
}
.wrapper {
    justify-content: center;
    width: 100%;
    margin: 5rem;
}
.responsiveVideo {
    width: 712px;
    height: 400px;
}
.subTitle-landscape{
    display: none;
}
@media (max-width: 390px) {
    body {
        overflow: visible;
    }
    .coachTitle,
    .refTitle {
        font-size: 3rem !important;
    }
    .coachInfo p,
    .refInfo p {
        font-size: 1.75rem !important;
    }
    .subjects .coachSubject,
    .subjects .refSubject {
        font-size: 0.65rem !important;
    }
    .details-container .details {
        font-size: clamp(0.65rem, 1.2vw, 2vh) !important;
    }
    .aboutTitle .titleOne,
    .aboutTitle .titleTwo {
        font-size: 22vw !important;
    }
    .journey .journeyNav {
        width: 100vw;
    }
    .contact-image-container .contact-title h1 {
        font-size: 1.5rem !important;
    }
}
@media (max-width: 1090px) and (max-height: 450px) {
    .details-container .details {
        font-size: clamp(0.5rem, 1.2vw, 1.5rem) !important;
    }
    .subTitle-landscape{
        display: inline-block;
    }
    .subTitle-1, .subTitle-2, .subTitle-3{
        display: none !important;
    }
  }
@media (max-width: 1090px) {
    .horizontalNav,
    .image-1 .detail-image-container,
    .journeyNav,
    .tooltip .tooltiptext {
        display: none;
    }
    #landingScreen {
        background-image: url(./images/landingImage.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .contact-title h1,
    .journeyNav {
        background-color: rgba(255, 255, 255, 0.5);
    }
    #overview .subTitle {
        padding: 1.5rem 0;
    }
    .overviewInfoContainer {
        height: 100%;
        width: auto;
    }
    .overviewInfoContainer p {
        margin: 0;
        padding: 1rem;
        font-size: 1.75rem;
    }
    .detail-4 p {
        width: 90%;
    }
    .overviewImageContainer {
        width: 100%;
    }
    #problem .subTitle {
        font-size: 4rem;
        margin-right: 0;
        width: 100%;
        left: 0;
        top: 2.5rem;
    }
    .wrapper {
        right: 59%;
        margin: 0;
    }
    .responsiveVideo {
        width: 316px;
        height: 178.5px;
    }
    #coachProduct,
    #refProduct {
        flex-direction: column;
        height: 200vh !important;
    }
    .canvasContainerCoach,
    .canvasContainerRef,
    .coachInfo,
    .modalContainerCoach,
    .modalContainerRef,
    .refInfo {
        width: 100vw !important;
    }
    .modalContainerRef {
        top: 50% !important;
    }
    .coachTitle,
    .refTitle {
        margin: 0 !important;
    }
    .coachInfo p,
    .details,
    .refInfo p {
        margin: 0 1rem !important;
    }
    .coachSubject,
    .refSubject {
        font-size: 1rem !important;
    }
    #coachCanvas,
    #refCanvas {
        right: 0 !important;
    }
    .aboutTitle {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }
    .journeyNav {
        width: 100%;
        padding-left: 0 !important;
        bottom: 0 !important;
        display: flex;
        justify-content: space-around;
    }
    .journeyDot {
        padding: 1% 0 !important;
    }
    .journeyDot svg:hover {
        cursor: pointer;
        fill: white;
        height: 48px !important;
        width: 48px !important;
    }
    .aboutTitle h1 {
        font-size: 7.5rem !important;
    }
    .details {
        font-size: clamp(0.85rem, 1.2vw, 1.5rem) !important;
        padding-bottom: 5rem !important;
        max-width: fit-content;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }
    .journeySubject .subTitle {
        margin-top: 2rem;
    }
    .slide-2-title {
        position: static !important;
        width: 100% !important;
        margin-top: 1rem !important;
    }
    .image-1 {
        background-image: url(./images/MCarthonSelfie.webp) !important;
    }
    .detail-1 {
        padding: 0 1rem !important;
        width: 100% !important;
    }
    .contact-title,
    .questions-list,
    .questions-title {
        padding-left: 0 !important;
    }
    .detail-4,
    .questions-page {
        flex-direction: column !important;
    }
    .questions-title {
        position: relative !important;
    }
    .contact-image-container {
        height: 50vh !important;
    }
    .contact-title {
        position: relative;
        text-align: center;
        color: #fff;
    }
    .contact-title h1 {
        border-radius: 2rem;
        margin: 1rem !important;
        font-size: 2rem;
        width: 50%;
    }
}
@media (min-width: 1090px) {
    #hamburgerNav {
        display: none;
    }
    #landingScreen #hamburgerNav {
        display: block;
    }
}
@media screen and (min-width: 1091px) and (max-width: 1150px) {
    #coachProduct,
    #refProduct {
        flex-direction: column;
        height: 200vh !important;
    }
    .canvasContainerCoach,
    .canvasContainerRef,
    .coachInfo,
    .modalContainerCoach,
    .modalContainerRef,
    .refInfo {
        width: 100vw !important;
    }
    .modalContainerRef {
        top: 50% !important;
    }
    #coachCanvas,
    #refCanvas {
        right: 0 !important;
    }
}
#coach,
#referee {
    width: 100%;
    height: 100%;
}
#coachProduct,
#refProduct {
    position: relative;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.canvasContainerCoach,
.canvasContainerRef {
    display: flex;
    align-items: center;
    width: 50vw;
    height: 100vh;
    overflow: clip;
}
#coachCanvas,
#refCanvas {
    position: relative;
    right: 50%;
    top: 2.5%;
    cursor: move;
    width: 100vw !important;
    height: auto !important;
}
.coachInfo,
.refInfo {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 50vw;
    height: 100vh;
    background-image: url("./images/LandingBG.webp");
}
.coachInfo p,
.refInfo p {
    font-size: 2rem;
    margin: 0 5rem;
    text-align: center;
    color: #000;
}
.coachTitle,
.refTitle {
    font-size: 4rem;
    margin: 4.25rem 0 1rem;
    padding-top: 1rem;
    border-bottom: 0.1875rem solid #a9a9a9;
    width: 80%;
    text-align: center;
    font-family: verdana;
    font-weight: 700;
    color: #000;
}
.coachSubject,
.modalContent h4,
.refSubject {
    font-family: Verdana, sans-serif;
    font-weight: 700;
    color: #000;
    text-align: center;
}
.subjects {
    justify-content: space-around;
    align-items: center;
    height: 20%;
    width: 100%;
}
.modalContainerCoach,
.modalContainerRef {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 50vw;
    top: 0;
}
.coachSubject,
.refSubject {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 15px;
    margin: 10px 0;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.content,
input {
    box-sizing: border-box;
    transition: all var(--sp) ease 0s;
}
.coachSubject:hover,
.refSubject:hover {
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
    font-family: verdana;
    font-weight: 700;
    background-color: #f5f5f5;
    border-color: #333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.modalContainerCoach {
    justify-content: center;
    align-items: center;
    left: 0;
}
.modalContainerRef {
    justify-content: center;
    align-items: center;
    right: 0;
}
.journey,
.modal,
.modalBody {
    position: relative;
}
.modal {
    background-color: #fff;
    border-radius: 10px;
    height: 50%;
    width: 80%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    padding: 2rem;
}
.modalBody {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 1rem;
    max-height: 70vh;
    box-sizing: border-box;
}
.modalClose {
    position: sticky;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
    color: #888;
    z-index: 2;
    padding: 0.5rem;
}
.modalClose:hover {
    color: red;
}
.modalContent h4 {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
}
.modalContent p {
    font-size: 1.8rem;
    margin: 0 4rem;
    text-align: center;
    color: #333;
    line-height: 1.6;
}
.about {
    background-image: url("./images/stadium.webp");
}
.journeyNav {
    position: fixed;
    bottom: 20%;
    padding-left: 2.5%;
    z-index: 1;
}
.journeyDot {
    padding: 20% 0;
}
.journeyDot svg:hover {
    cursor: pointer;
    fill: white;
    height: 110%;
    width: 110%;
}
.aboutTitle,
.journeySlide {
    width: 100vw;
}
.aboutTitle {
    background-image: url("./images/aboutToss.webp");
    background-clip: text;
    -webkit-background-clip: text;
    padding-top: 0.5rem;
    height: 100vh;
}
.aboutTitle h1 {
    text-align: center;
    margin: 0;
    color: transparent;
    font-size: 21.5rem;
    font-weight: 900;
}
.journeySlide {
    height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}
.journeySubject {
    align-items: center;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
}
.image-1 .journeySubject {
    flex-direction: row;
    justify-content: space-between !important;
}
.image-2 .journeySubject {
    background-color: rgba(0, 0, 0, 0.35);
}
.detail-1 {
    flex-direction: column;
    justify-content: space-around !important;
    width: 50%;
    height: 100% !important;
    padding: 0 2rem 0 7rem;
}
.detail-1 h4 {
    margin-top: 2.5rem;
}
.image-1 .detail-image-container {
    width: 45%;
    height: 100%;
    background-image: url("./images/MCarthonSelfie.webp");
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.journeySubject .subTitle {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
}
.slide-2-title {
    display: grid;
    position: relative;
    margin-top: 6.25rem;
    right: 21%;
    width: 40%;
    font-size: 4rem;
    color: #fff;
}
.subTitle-1,
.subTitle-2,
.subTitle-3 {
    justify-self: center;
    margin: 0;
}
.details-container {
    align-items: center;
    height: 60%;
}
.detail-2 .detail-image-container,
.detail-4 .detail-image-container {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.details {
    justify-self: center;
    align-items: center;
    font-family: Barlow, serif;
    color: #fff;
    font-size: clamp(0.65rem, 1.3vw, 3vh);
    height: 100%;
    width: 125%;
    margin: 0 0 0 7rem;
}
.detail-1 p {
    width: 100%;
    height: auto;
    margin: 0;
}
.detail-2 p,
.detail-5 p,
.detail-6 p {
    margin-right: 7rem;
}
.detail-2 .detail-image-container {
    border-radius: 100%;
    background-image: url("./images/MCarthonBBall.webp");
}
.detail-4 .detail-image-container {
    background-image: url("./images/TOSSPatentCover.webp");
}
.journeySlide .image-1,
.journeySlide .image-2,
.journeySlide .image-3,
.journeySlide .image-4,
.journeySlide .image-5,
.journeySlide .image-6 {
    opacity: 1;
}
.image-1 {
    background-image: url("./images/slide4.webp");
}
.image-2 {
    background-image: url("./images/MCarthonBBall.webp");
}
.image-4 {
    background-image: url("./images/slide1.webp");
}
.image-5 {
    background-image: url("./images/ice-hockey.webp");
}
.image-6 {
    background-image: url("./images/adaptive.webp");
}
#wrapper {
    width: 42.5rem;
    display: table;
    margin: auto;
}
.questions-page {
    align-items: center;
    height: auto;
    width: 100vw;
    padding-top: 7rem;
    margin-bottom: 7rem;
}
.questions-title {
    position: fixed;
    text-align: center;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #333;
    padding-left: 5rem;
}
.questions-list {
    justify-content: center;
    align-items: center;
    height: auto;
    width: 75vw;
    padding-left: 18rem;
    gap: 25px;
}
.question-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    transition: background-color 0.3s, box-shadow 0.3s;
}
.question-container:hover {
    background-color: #f0f0f0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.question {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
}
.question button {
    background: 0 0;
    border: none;
    font-size: 1.8rem;
    color: #007bff;
    cursor: pointer;
    margin-left: 10px;
    transition: transform 0.2s;
}
.question button:hover {
    transform: scale(1.2);
}
.answer {
    margin-top: 15px;
    font-size: 1.2rem;
    color: #555;
    line-height: 1.7;
    width: 100%;
}
.contact-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.contact-image-container {
    height: 60vh;
    width: 100vw;
    background-image: url("./images/MCarthonBBallBlue.webp");
}
.contact-title {
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 3rem;
    color: rgba(0, 0, 0, 0.8);
    font-size: 2rem;
}
.contact-subtitle {
    font-size: 3.5rem;
    color: rgba(0, 0, 0, 0.8);
}
.contact-info {
    text-align: center;
    color: #333;
    max-width: 37.5rem;
    font-size: 1.5rem;
    line-height: 1.6;
}
.contact-info a {
    color: #007bff;
    text-decoration: none;
}
.contact-info a:hover {
    text-decoration: underline;
}
.tooltip {
    position: relative;
    display: inline-block;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    white-space: nowrap;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    top: 0.6rem;
    left: 120%;
}
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #000 transparent transparent;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}